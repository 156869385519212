$(document).ready(function () {
    $('.plain-cards, .partner-cards').each(function() {
        let el = $(this);
        setCardsPerRow(el);
        $(window).on('resize', function() {
            setCardsPerRow(el);
        })
    })

    function setCardsPerRow(element) {
        let cardsPerRowDesktop = $(element).attr('data-cards-per-row-desktop');
        let cardsPerRowTablet = $(element).attr('data-cards-per-row-tablet');
        let cardsPerRowMobile = $(element).attr('data-cards-per-row-mobile');
        if (window.matchMedia("(min-width: 1024px)").matches) {
            $(element).css('gridTemplateColumns', `repeat(${cardsPerRowDesktop}, 1fr)`)
        } else if (window.matchMedia("(min-width: 768px)").matches) {
            $(element).css('gridTemplateColumns', `repeat(${cardsPerRowTablet}, 1fr)`)
        } else {
            $(element).css('gridTemplateColumns', `repeat(${cardsPerRowMobile}, 1fr)`)
        }
    }
})