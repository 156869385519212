/* ==========================================================================
    Standard Multi-level and Mobile Navigation
========================================================================== */

jQuery(document).ready(function($){

    $('.main-nav li ul').siblings('a').addClass('hasChildren').before('<a class="arrow" href="#"><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.31659 7C3.18575 6.99923 3.05656 6.97083 2.93746 6.91667C2.80865 6.8599 2.6989 6.76724 2.62133 6.64978C2.54376 6.53231 2.50164 6.395 2.5 6.25424V2.74625C2.50164 2.60549 2.54376 2.46818 2.62133 2.35071C2.6989 2.23324 2.80865 2.14059 2.93746 2.08381C3.08567 2.0138 3.25057 1.98684 3.41336 2.00599C3.57615 2.02514 3.73028 2.08964 3.8582 2.19213L5.98299 3.94613C6.06632 4.01246 6.13361 4.09674 6.17986 4.19267C6.22611 4.28861 6.25013 4.39374 6.25013 4.50024C6.25013 4.60675 6.22611 4.71188 6.17986 4.80781C6.13361 4.90375 6.06632 4.98802 5.98299 5.05436L3.8582 6.80835C3.70504 6.93256 3.51378 7.00024 3.31659 7Z" fill="#F05E22"/></svg></a>');

    let currentScreenSize = window.screen.width;

    function setArrowHandling(screenSize) {
        $('.arrow').off('click');
        
        if (currentScreenSize < 1024) {
            $('.arrow').on("click", function(e) {
                console.log('CLICK')
                let submenuOpen = $(this).hasClass('arrowOpen');
                if (!submenuOpen) {
                    $(this).siblings('.sub-menu').slideDown('fast');
                    $(this).addClass('arrowOpen');
                } else {
                    $(this).siblings('.sub-menu').slideUp('fast');
                    $(this).removeClass('arrowOpen');
                }
                $('.arrow').each(function(el) {
                    let itemWidth = $(this).siblings('.hasChildren').innerWidth();
                    $(this).css('left', `calc(${itemWidth}px + 20px)`);
                })
                e.preventDefault();
            });
        } else {
            $('.arrow').each(function(el) {
                let itemWidth = $(this).siblings('.hasChildren').innerWidth();
                $(this).css('left', `calc(${itemWidth}px + 10px)`);
            })
        }


    }
    setArrowHandling(currentScreenSize);

    $(window).resize(function () {
        currentScreenSize = window.screen.width;
        setArrowHandling(currentScreenSize);
    })


    // Mobile menu toggle
    $('.menuToggle').on('click',function(e){
        e.preventDefault();
        $('body').toggleClass('menuOpen');
        $('#search-box').prependTo('.main-nav__links');
        $('.main-nav__links, #search-box').slideToggle('fast');
        $(this).toggleClass('close');
        $('.arrow').each(function(el) {
            let itemWidth = $(this).siblings('.hasChildren').innerWidth();
            $(this).css('left', `calc(${itemWidth}px + 20px)`);
        })
    });

    // Reveal/hide navigation on scroll up/down
    let body = $('body');
    let lastScroll = 0;
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.removeClass('scrollUp');
        }

        if (!body.hasClass('menuOpen')) {
            if ((currentScroll > lastScroll) && !body.hasClass('scrollDown') && currentScroll > 130) {
                // down
                body.removeClass('scrollUp');
                body.addClass('scrollDown');
            } else if ((currentScroll < lastScroll) && body.hasClass('scrollDown')) {
                // up
                body.removeClass('scrollDown');
                body.addClass('scrollUp');
            }

            lastScroll = currentScroll;
        }
    });

});
