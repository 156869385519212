jQuery(document).ready(function($) {

    if ($('#word-swap').length) {

        let wordsAttr = $('#word-swap').attr('data-words');
        let words = wordsAttr.split(",");
        let i = 0;

        function _getChangedText() {
            i = (i + 1) % words.length;
            return words[i];
        }

        function _changeText() {
            let txt = _getChangedText();
            $('.word-swap-item').fadeOut(700, function(){
                $(this).html(txt);
                $(this).fadeIn(700);
            });
        }

        setInterval(() => {
            _changeText();
        }, 3000);
    }

    // if ($('.word-swap').length) {
    //     $('.word-swap').each(function(i) {
    //         const elts = {
    //             text1: $(this).find(".word1"),
    //             text2: $(this).find(".word2")
    //         };
    //
    //         let texts = $(this).attr('data-words');
    //         const textsArr = texts.split(",");
    //
    //         const morphTime = 1.5;
    //         const cooldownTime = 1;
    //
    //         let textIndex = textsArr.length - 1;
    //         let time = new Date();
    //         let morph = 0;
    //         let cooldown = cooldownTime;
    //
    //         elts.text1.text(textsArr[textIndex % texts.length]);
    //         elts.text2.text(textsArr[(textIndex + 1) % texts.length]);
    //
    //         function doMorph() {
    //             morph -= cooldown;
    //             cooldown = 0;
    //
    //             let fraction = morph / morphTime;
    //
    //             if (fraction > 1) {
    //                 cooldown = cooldownTime;
    //                 fraction = 1;
    //             }
    //
    //             setMorph(fraction);
    //         }
    //
    //         function setMorph(fraction) {
    //             elts.text2.css('filter', `blur(${Math.min(8 / fraction - 8, 100)}px)`);
    //             elts.text2.css('opacity', `${Math.pow(fraction, 0.4) * 100}%`);
    //
    //             fraction = 1 - fraction;
    //             elts.text1.css('filter', `blur(${Math.min(8 / fraction - 8, 100)}px)`);
    //             elts.text1.css('opacity', `${Math.pow(fraction, 0.4) * 100}%`);
    //
    //             elts.text1.text(textsArr[textIndex % textsArr.length]);
    //             elts.text2.text(textsArr[(textIndex + 1) % textsArr.length]);
    //         }
    //
    //         function doCooldown() {
    //             morph = 0;
    //
    //             elts.text2.css('filter' ,"");
    //             elts.text2.css('opacity', "100%");
    //
    //             elts.text1.css('filter', "");
    //             elts.text1.css('opacity', "0%");
    //         }
    //
    //         function animate() {
    //             requestAnimationFrame(animate);
    //
    //             let newTime = new Date();
    //             let shouldIncrementIndex = cooldown > 0;
    //             let dt = (newTime - time) / 1000;
    //             time = newTime;
    //
    //             cooldown -= dt;
    //
    //             if (cooldown <= 0) {
    //                 if (shouldIncrementIndex) {
    //                     textIndex++;
    //                 }
    //
    //                 doMorph();
    //             } else {
    //                 doCooldown();
    //             }
    //         }
    //
    //         animate();
    //     })
    // }
})
