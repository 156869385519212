$(document).ready(function () {
    $('.vertical-spacer').each(function() {
        let desktopHeight = $(this).attr('data-desktop-height');
        let tabletHeight = $(this).attr('data-tablet-height');
        let mobileHeight = $(this).attr('data-mobile-height');
        if (window.matchMedia("(min-width: 768px)").matches) {
            $(this).css('paddingTop', tabletHeight)
        } else if (window.matchMedia("(min-width: 1024px)").matches) {
            $(this).css('paddingTop', desktopHeight)
        } else {
            $(this).css('paddingTop', mobileHeight)
        }
    })
})