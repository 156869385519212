jQuery(document).ready(function($){

    const counters = document.querySelectorAll('.statistics__item--number');
    const speed = 400;

    if (counters.length) {
        var observer = new IntersectionObserver(function (entries) {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            if (entries[0].isIntersecting === true) {
                if (entries[0]['intersectionRatio'] > 0.6) {
                    counters.forEach(counter => {
                        const animate = () => {
                            const originalValue = counter.getAttribute('data-target-num');
                            const value = +counter.getAttribute('data-target-num').replace(/\D/g, '');
                            let nonNumericChars = counter.getAttribute('data-target-num').replace(/[0-9]/g, '');
                            const data = +counter.innerText;

                            const time = value / speed;
                            if (data < value) {
                                counter.innerText = Math.ceil(data + time);
                                setTimeout(animate, 1);
                            } else {
                                counter.innerText = originalValue;
                            }

                        }

                        animate();
                    });
                }
            }
        }, {threshold: [0.6]});

        observer.observe(document.querySelector(".statistics"));
    }
});
