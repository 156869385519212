import $ from 'jquery';
// import 'slick-carousel';

// Swiper documentation: https://swiperjs.com/swiper-api
import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    EffectCoverflow,
    Thumbs,
    FreeMode
} from 'swiper/swiper.esm.js';

jQuery(document).ready(function() {

    Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Thumbs, FreeMode]);

    let lightboxGalleryThumbnails = new Swiper(".lightbox-gallery__thumbnails", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
    });
    let lightboxGallerySlider = new Swiper(".lightbox-gallery__slider", {
        spaceBetween: 40,
        loop: true,
        navigation: {
            prevEl: '.lightbox-arrow-controls__prev',
            nextEl: '.lightbox-arrow-controls__next',
        },
        thumbs: {
            swiper: lightboxGalleryThumbnails,
        },
    });

    const testimonialsSwiper = new Swiper('.testimonials__slider', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,

        // If we need pagination
        pagination: {
            el: '.testimonials-pagination',
            type: 'progressbar'
        },
        navigation: {
            prevEl: '.testimonials-arrow-controls__prev',
            nextEl: '.testimonials-arrow-controls__next',
        }
    });

    testimonialsSwiper.on('slideChange', function () {
        if (this.isBeginning) {
            $('.testimonials-arrow-controls__prev').addClass('disabled-arrow');
            $('.testimonials-arrow-controls__next').removeClass('disabled-arrow')
        }
        else if (this.isEnd) {
            $('.testimonials-arrow-controls__prev').removeClass('disabled-arrow')
            $('.testimonials-arrow-controls__next').addClass('disabled-arrow')
        }
        else {
            $('.testimonials-arrow-controls__prev').removeClass('disabled-arrow')
            $('.testimonials-arrow-controls__next').removeClass('disabled-arrow')
        }
    });

    const projectsSwiper = new Swiper('.projects-slider__slider', {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 0,
        slidesPerView: 1.25,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 3.25,
            },
            1024: {
                slidesPerView: 4.25,
            }
        },
        pagination: {
            el: '.projects-progressbar',
            type: 'progressbar'
        },
        navigation: {
            prevEl: '.projects-arrow-controls__prev',
            nextEl: '.projects-arrow-controls__next',
        }
    });

    projectsSwiper.on('slideChange', function () {
        if (this.isBeginning) {
            $(this.navigation.prevEl).addClass('disabled-arrow');
            $(this.navigation.nextEl).removeClass('disabled-arrow')
        }
        else if (this.isEnd) {
            $(this.navigation.prevEl).removeClass('disabled-arrow')
            $(this.navigation.nextEl).addClass('disabled-arrow')
        }
        else {
            $(this.navigation.prevEl).removeClass('disabled-arrow')
            $(this.navigation.nextEl).removeClass('disabled-arrow')
        }
    });

    const airframeImageSliderExterior = new Swiper('.airframe-images-slider__exterior', {
        // Optional parameters
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 4.5,
            }
        },
        navigation: {
            prevEl: '.exterior-prev-arrow',
            nextEl: '.exterior-next-arrow',
        }
    });

    airframeImageSliderExterior.on('slideChange', function () {
        console.log(this.navigation.nextEl)
        if (this.isBeginning) {
            $(this.navigation.prevEl).hide()
            $('.airframe-slider-prev-gradient').hide()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
        else if (this.isEnd) {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).hide()
            $('.airframe-slider-gradient').hide()
        }
        else {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
    });

    const airframeImageSliderInterior = new Swiper('.airframe-images-slider__interior', {
        // Optional parameters
        loop: true,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 4.5,
            }
        },
        navigation: {
            prevEl: '.interior-prev-arrow',
            nextEl: '.interior-next-arrow',
        },
    });

    airframeImageSliderInterior.on('slideChange', function () {
        if (this.isBeginning) {
            $(this.navigation.prevEl).hide()
            $('.airframe-slider-prev-gradient').hide()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
        else if (this.isEnd) {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).hide()
            $('.airframe-slider-gradient').hide()
        }
        else {
            $(this.navigation.prevEl).show()
            $('.airframe-slider-prev-gradient').show()
            $(this.navigation.nextEl).show()
            $('.airframe-slider-gradient').show()
        }
    });

    const basicImagesSlider = new Swiper('.basic-images-slider', {
        // Optional parameters
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 4.5,
            }
        },
        navigation: {
            prevEl: '.basic-prev-arrow',
            nextEl: '.basic-next-arrow',
        },
    });

    basicImagesSlider.on('slideChange', function () {
        if (this.isBeginning) {
            $(this.navigation.prevEl).hide()
            $('.basic-slider-prev-gradient').hide()
            $(this.navigation.nextEl).show()
            $('.basic-slider-gradient').show()
        }
        else if (this.isEnd) {
            $(this.navigation.prevEl).show()
            $('.basic-slider-prev-gradient').show()
            $(this.navigation.nextEl).hide()
            $('.basic-slider-gradient').hide()
        }
        else {
            $(this.navigation.prevEl).show()
            $('.basic-slider-prev-gradient').show()
            $(this.navigation.nextEl).show()
            $('.basic-slider-gradient').show()
        }
    });

    const videoRepeaterSlider = new Swiper('.video-repeater-slider', {
        // Optional parameters
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
            nextEl: '.video-arrows__forward',
            prevEl: '.video-arrows__back',
            enabled: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2.5,
                navigation: {
                    enabled: true
                },
                pagination: {
                    enabled: false
                }
            },
            1024: {
                slidesPerView: 2.5,
            }
        },

    });

    const basicCardsSlider = new Swiper('.basic-cards-slider', {
        // Optional parameters
        loop: false,
        spaceBetween: 20,
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
            nextEl: '.basic-cards__next',
        },

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3.5,
            }
        },

    });
    //
    // var basicCardSlider = jQuery(".basic-cards-slider");
    // jQuery(".basic-cards-slider").each(function(i){
    //     let numberOfCards = $(this).find('.basic-cards__card').length;
    //     let basicCardSliderInstance = [];
    //
    //     basicCardSliderInstance[i] = jQuery(this).slick({
    //         adaptiveHeight: true,
    //         infinite: false,
    //         slidesToShow: 3.5,
    //         slidesToScroll: 1,
    //         arrows: false,
    //         dots: false,
    //         autoplay: false,
    //         pauseOnHover: false,
    //         responsive: [
    //             {
    //                 breakpoint: 1024,
    //                 settings: {
    //                     slidesToShow: 2
    //                 }
    //             },
    //             {
    //                 breakpoint: 768,
    //                 settings: {
    //                     slidesToShow: 1
    //                 }
    //             },
    //         ]
    //     });
    //
    //     if (numberOfCards <= basicCardSlider.slick('slickGetOption','slidesToShow')) {
    //         $(this).siblings('.basic-cards__controls').hide();
    //     }
    //     jQuery(".basic-cards__next").click(function () {
    //         basicCardSliderInstance[i].slick("slickNext");
    //     });
    //
    // });
});
